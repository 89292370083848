
<template >
  <div>
    <div class="page">
      <div class="vip">
        <div class="title">
          <div class="icon">
            <i
              class="ri-money-cny-circle-line"
              style="
                font-size: 35px;
                color: rgb(10, 108, 255);
                vertical-align: middle;
                padding-right: 5px;
              "
            ></i>
          </div>
          <div class="text">确认订单</div>
        </div>
        <div class="descriptions">
          <div class="box_1">
            <div class="serve">
              <span class="word">订阅服务：</span>
              <span class="word">高级版</span>
            </div>
            <div class="number">
              <span class="word">订阅账号：</span>
              <span class="word">13790519323</span>
            </div>
          </div>
          <div class="box_2">
            <div style="display: flex">
              <span class="word">购买方案：</span>
              <div class="style">
                <div style="font-size: 16px">12个月</div>
                <div style="font-size: 14px">599元1年</div>
              </div>
              <div class="style">
                <div style="font-size: 16px">1个月</div>
                <div style="font-size: 14px">99元1个月</div>
              </div>
              <div class="style">
                <div style="font-size: 16px">12个月</div>
                <div style="font-size: 14px">990元1年</div>
              </div>
            </div>
            <div class="time">
              <span class="word">有效截止日期</span>
              <span class="word">2023-11-02 00:00:00</span>
            </div>
          </div>
          <div class="box_3">
            <div class="money">
              <span class="word">订单金额：</span>
              <span class="word1">￥990.0</span>
            </div>
            <div class="pay">
              <span class="word">订单金额：</span>
              <div class="paystyle">
                <i
                  class="ri-alipay-fill"
                  style="
                    font-size: 28px;
                    color: rgb(10, 108, 255);
                    vertical-align: middle;
                    padding-right: 5px;
                  "
                ></i>
                <span style="font-size: 15px">支付宝</span>
              </div>
            </div>
          </div>
          <div class="box_4">
            <div class="agree">
              <el-checkbox v-model="checked">同意</el-checkbox>
              <a href="#">《来搜销售条款》</a>
            </div>
            <div class="paynow">立即支付</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      checked: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.page {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #f0f2f5;
}
.vip {
  width: 800px;
  border-radius: 12px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin: 100px auto;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0 12px 24px;
    border-bottom: 4px solid #0a6cff;
    .icon {
      font-size: 15px;
    }
    .text {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #0a6cff;
    }
  }
  .descriptions {
    box-sizing: border-box;
    padding: 24px;
    .word {
      margin-right: 25px;
      line-height: 30px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
    }
    .box_1 {
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .box_2 {
      padding: 24px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      .style {
        width: 150px;
        height: 70px;
        text-align: center;
        line-height: 30px;
        padding: 5px;
        margin-right: 15px;
        margin-bottom: 25px;
        color: rgba(0, 0, 0, 0.65);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .box_3 {
      padding: 24px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      .word1 {
        font-size: 24px;
        font-weight: 600;
        color: #fc4c4f;
      }
      .paystyle {
        display: inline-block;
        width: 160px;
        height: 40px;
        border-radius: 4px;
        background: #e6f0ff;
        border: 1px solid #0a6cff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
    }
    .box_4 {
      padding: 24px 0 24px 90px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      a {
        color: #0a6cff;
        text-decoration: none;
      }
      .paynow {
        width: 160px;
        height: 32px;
        font-size: 15px;
        text-align: center;
        line-height: 32px;
        border-radius: 4px;
        color: #fff;
        border-color: #2986ff;
        background: #2986ff;
        margin-top: 15px;
      }
    }
  }
}
</style>