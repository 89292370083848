<template>
  <div class="page">
    <el-container>
      <el-aside class="nav" width="200px">
        <div class="header">
          <div class="logo">
            <img width="34" height="34" src="@/assets/logo.png" alt="" />
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="desc">
            <span>订阅中心</span>
          </div>
        </div>
        <!-- <div class="menu">
          <div
            class="item"
            :class="
              crouter === '/subscriptionCenter/aliRfqSubscription'
                ? 'active'
                : ''
            "
            @click="modulechange('/subscriptionCenter/aliRfqSubscription')"
          >
            <span>RFQ发布监控</span>
          </div>
          <div
            class="item"
            :class="
              crouter === '/subscriptionCenter/aliExpressMonitor'
                ? 'active'
                : ''
            "
            @click="modulechange('/subscriptionCenter/aliExpressMonitor')"
          >
            <span>Aliexpress商品监控</span>
          </div>
          <div
            class="item"
            :class="
              crouter === '/subscriptionCenter/aliShopeeMonitor' ? 'active' : ''
            "
            @click="modulechange('/subscriptionCenter/aliShopeeMonitor')"
          >
            <span>Shopee商品监控</span>
          </div>
        </div> -->
<!--        <div class="menu">-->
<!--        <el-row >-->
<!--          <el-col >-->
<!--&lt;!&ndash;            <el-menu class="tac" @open="handleOpen" @close="handleClose">&ndash;&gt;-->
<!--            <el-menu class="tac" >-->
<!--              <el-submenu-->
<!--                ><template slot="title">-->
<!--                  <i class="el-icon-location"></i>-->
<!--                  <span>Alibaba</span>-->
<!--                </template>-->
<!--                <el-menu-item class="tac">RFQ发布监控</el-menu-item>-->
<!--              </el-submenu>-->
<!--            </el-menu>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        </div>-->
    <el-menu background-color="#F8F8F8">
          <el-menu-item
            index="1"
            class="item"
            :class="
              crouter === '/subscriptionCenter/aliRfqSubscription'
                ? 'active'
                : ''
            "
            @click="modulechange('/subscriptionCenter/aliRfqSubscription')"
          >
            <i class="el-icon-video-camera"></i>
            <span>RFQ发布监控</span>
          </el-menu-item>
          <!-- <el-menu-item
            index="2"
            class="item"
           :class="
              crouter === '/subscriptionCenter/aliExpressMonitor'
                ? 'active'
                : ''
            "
            @click="modulechange('/subscriptionCenter/aliExpressMonitor')"
          >
            <i class="el-icon-sell
"></i>
            <span>Aliexpress商品监控</span>
          </el-menu-item>
          <el-menu-item
            index="3"
            class="item"
             :class="
              crouter === '/subscriptionCenter/aliShopeeMonitor' ? 'active' : ''
            "
            @click="modulechange('/subscriptionCenter/aliShopeeMonitor')"
          >
            <i class="el-icon-shopping-cart-2"></i>
            <span>Shopee商品监控</span>
          </el-menu-item> -->
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-wind-power"></i>
              <span>学标杆</span>
            </template>
            <el-menu-item
              index="4-1"
              class="item"
              :class="
                crouter === '/subscriptionCenter/BenchmarkStoreRecommendation'
                  ? 'active'
                  : ''
              "
              @click="
                modulechange('/subscriptionCenter/BenchmarkStoreRecommendation')
              "
              ><span>标杆店铺推荐</span></el-menu-item
            >
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container>
        <el-main class="main">
          <router-view />
        </el-main>
        <el-footer class="footer">
          <div class="item">
            <span>使用条款</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>隐私政策</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>联系客服</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>©来搜</span>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crouter: "",
    };
  },
  mounted() {
    this.crouter = this.$route.path;
    console.log(this.crouter);
  },
  methods: {
    modulechange(p) {
      this.crouter = p;
      this.$router.push(p);
    },
  },
};
</script>

<style scoped>
.page {
  overflow: hidden;
}

.nav {
  user-select: none;
  height: 100vh;
  overflow: hidden;
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
}

.header .logo {
  padding: 0 20px;
}

.header .vcut {
  height: 24px;
  margin: 0;
}

.header .desc {
  margin-left: 20px;
  font-size: 14px;
}

/* .menu .item {
  font-size: 13px;
  color: #515151;
  display: flex;
  height: 46px;
  align-items: center;
  padding-left: 30px;
}

.menu .item:hover {
  cursor: pointer;
  background-color: #eee;
}

.menu .item:hover span {
  background: rgba(0, 0, 0, 0);
} */

/* .menu .item.active {
  color: #ff6a00;
} */
::v-deep .el-menu-item.active {
  color: #ff6a00;
}
::v-deep .el-menu {
  border-right: 0;
}

::v-deep .el-menu-item:hover {
  background-color: #eee !important;
}


.main {
  height: calc(100vh - 60px);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  min-width: 450px;
  overflow: hidden;
}

.footer .item {
  padding: 0 10px;
  color: #999;
}

.footer .item:hover {
  cursor: pointer;
}
.tac{
  background-color: rgb(248, 248, 248);
  box-shadow: none;
  height: 0px;
}
</style>
