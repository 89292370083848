import { render, staticRenderFns } from "./buyVip.vue?vue&type=template&id=dc23a1be&scoped=true&"
import script from "./buyVip.vue?vue&type=script&lang=js&"
export * from "./buyVip.vue?vue&type=script&lang=js&"
import style0 from "./buyVip.vue?vue&type=style&index=0&id=dc23a1be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc23a1be",
  null
  
)

export default component.exports